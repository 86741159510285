import React from 'react'
import Layout from "../components/Layout"
import { Seo } from '../components/Seo'


import ServicesBanner from '../components/services/servicesBanner'
import CxDesign from '../components/services/cx-design'
import PlatformEngineering from '../components/services/platform-engineering';
import ECommerce from '../components/services/e-commerce';
import Experimentation from '../components/services/experimentation';

export default function() {

    return (
    <Layout>
        <Seo 
          title="Services - Sogody" 
          description="Deep dive into tech and strategy updates of the Martech industry and beyond." 
        />
        <div className='services-page'>
          <ServicesBanner/>
          <CxDesign />
          <PlatformEngineering />
          <ECommerce />
          <Experimentation />
        </div>
    </Layout>
    )
}
