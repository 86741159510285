import React from "react"
import { Row, Col, Container} from 'react-bootstrap';

import BannerImg1 from '../../assets/images/services/services-banner-1.png';
import BannerImg2 from '../../assets/images/services/services-banner-2.png';
import BannerImg3 from '../../assets/images/services/services-banner-3.png';
import BannerImg4 from '../../assets/images/services/services-banner-4.png';


import ContactUs from "../ContactUs";

export default function() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className="services-banner">  
            <Container> 
                <Row>
                    <Col xs={12} md={12} lg={6} className="banner-title">
                        <h1 className="name">Engineering digital experiences to have clients convert without pitching</h1>
                        <p>A diverse range of skills that embraces a specific set of fundamentals, talent and knowledge expertise in each area.</p>
                        <button 
                            onClick={() => setModalShow(true)} 
                        >Get in touch</button>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="banner-img-ctn">
                        <div><img src={BannerImg1}  alt="banner image"/></div>
                        <div><img src={BannerImg2}  alt="banner image"/></div>
                        <div><img src={BannerImg3}  alt="banner image"/></div>
                        <div><img src={BannerImg4}  alt="banner image"/></div>
                    </Col>
                </Row> 
                <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
            </Container>
        </div>
    )
}



