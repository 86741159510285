import React from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';

import strategiesAndHypothesis from '../../assets/images/services/strategies&hypothesis.png';
import eventTracking from '../../assets/images/services/event-tracking.png';
import codingAB from '../../assets/images/services/codingAB.png';


export default function() {
    return (
        <div className="experimentation-service services-section">  
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <div className="services-section-head">
                            <h2 className="services-section-title">Experimentation Services</h2> 
                            <span className="services-section-desc">Adding data science and technology smarts to marketing and product teams. Making sure that brands always deliver relevant experiences, while also saving time and money.</span>
                            <Button href="/services/experimentation/" >Learn more <img src={require('../../assets/images/next_service.png')} alt="arrow right" /></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="services-section-cards">
                    <Col xs={12} md={12} lg={6} className="left-col">
                        <div className="half-cards-ctn">
                            <div className='services-section-cards-card half-card'>
                                <h3>Testing strategies and hypothesis proofing</h3>
                                <p>Achieving statistically significant improvements by making low-risk modifications based on qualitative client-based testing.</p>
                                <img src={strategiesAndHypothesis} alt="strategies and hypothesis services" className='space-top' />
                            </div>
                            <div className='services-section-cards-card half-card'>
                                <h3>Event tracking and analytics</h3>
                                <p>Enabling experience optimization by making data-backed decisions that affect the brand’s core conversion metrics and KPIs.</p>
                                <img src={eventTracking} alt="event tracking" className='space-top'/>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="right-col">
                        <div className='services-section-cards-card full-card'>
                            <h3>Coding of A/B & Multivariate testing</h3>
                            <p>Coding clean tests to eliminate the guesswork out of website optimization and solve user pain points.</p>
                            <img src={codingAB} alt="coding A/B" className="corner-img"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
