import React from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import userInterface from '../../assets/images/services/user-interface.webm';
import endtoEndVideo from '../../assets/images/services/end-to-end.webm';
import cxInsights from '../../assets/images/services/cx-insights.webm';


export default function() {
    return (
        <div className="cx-design-services services-section">  
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <div className="services-section-head">
                            <h2 className="services-section-title">CX & Design Services</h2> 
                            <span className="services-section-desc">Strategically creating, delivering, and optimizing rich and personalized digital experiences that solve unmet customer needs and deliver brand value.</span>
                            <Button href="/services/cx-design/" >Learn more <img src={require('../../assets/images/next_service.png')} alt="arrow right" /></Button>
                        </div>
                    </Col>
                </Row>
                <Tabs>
                    <div className="cx-design-tabs services-section-tabs">
                        <Row>
                            <Col xs={12} md={12} lg={6} className="tabs-list-ctn">
                                <TabList>
                                    <Tab>
                                        <h3>User interface design</h3>
                                        <p>Brand-strengthening digital interfaces with user-focused, customer-centric experiences that deliver tangible business results, by attracting and retaining users.</p>
                                    </Tab>
                                    <Tab> 
                                        <h3>End-to-end product design</h3>
                                        <p>Building and optimizing digital experiences with the personalized, effective, and responsive technology needed to produce the highest impact on today's customer-first brand experience.</p>
                                    </Tab>
                                    <Tab>
                                        <h3>CX insights and audit</h3>
                                        <p>Providing brands the ability to see all aspects of their customer experience in a holistic and impartial way and helping them improve their CX delivery and rating.</p>
                                    </Tab>
                                </TabList>
                            </Col>
                            <Col xs={12} md={12} lg={6} className="gif-img-ctn">
                                <TabPanel>
                                    <video loop={true} autoPlay="autoplay" muted>
                                        <source src={userInterface} type="video/webm"></source>
                                    </video>
                                </TabPanel>
                                <TabPanel>
                                    <video loop={true} autoPlay="autoplay" muted>
                                        <source src={endtoEndVideo} type="video/webm"></source>
                                    </video>
                                </TabPanel>
                                <TabPanel>
                                    <video loop={true} autoPlay="autoplay" muted>
                                        <source src={cxInsights} type="video/webm"></source>
                                    </video>
                                </TabPanel>
                            </Col>
                        </Row> 
                    </div>
                </Tabs>
            </Container>
        </div>
    )
}
