import React from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';

import WebMobileEngineering from '../../assets/images/services/webMobileEngineering.png';
import AlwaysOnManaged from '../../assets/images/services/alwaysOnManaged.png';
import ThirdParty from '../../assets/images/services/3rd-party.png';


export default function() {
    return (
        <div className="platform-engineering-service services-section">  
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <div className="services-section-head">
                            <h2 className="services-section-title">Platform Engineering Services</h2> 
                            <span className="services-section-desc">Applying cutting-edge technologies to build the foundation for growth. We build and run complex platforms that give brands digital products that stand out.</span>
                            <Button href="/services/technology-engineering/" >Learn more <img src={require('../../assets/images/next_service.png')} alt="arrow right" /></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="services-section-cards">
                    <Col xs={12} md={12} lg={6} className="left-col">
                        <div className='services-section-cards-card full-card'>
                            <h3>Web & mobile engineering</h3>
                            <p>Enabling scalable and fast development. Helping brands to unlock greater control over their users’ digital experiences.</p>
                            <img src={WebMobileEngineering} alt="Web & mobile engineering" />
                            <h4>Numerous successful projects</h4>
                            <Button href="/work">Read our Success Stories</Button>
                            <p>Successfully delivering future-proof digital experiences with the customer at the heart of our design thinking.</p>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="right-col">
                        <div className="half-cards-ctn">
                            <div className='services-section-cards-card half-card less-space'>
                                <img src={AlwaysOnManaged} alt="always-on managed services" className='space-bottom' />
                                <h3>Always-on managed services</h3>
                                <p>As your digital partner, we leverage a business-driven agile framework, proven methodologies, and strategic partnerships to continuously deliver value to your business cost-effectively.</p>
                            </div>
                            <div className='services-section-cards-card half-card less-space'>
                                <h3>3rd party (API) integrations</h3>
                                <p>Driving revenue, flexibility, and speed with flexible solutions and API integrations such as email marketing, tag management, loyalty platforms and many more.</p>
                                <img src={ThirdParty} alt="3rd party api" className='space-top width-half bottom'/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
