import React from 'react';
import { Row, Col, Container,Button} from 'react-bootstrap';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ECommerceEngineering from '../../assets/images/services/e-commerce-engineering.webm';
import ECommerceStrategy from '../../assets/images/services/e-commerce-strategy.webm';
import Analytics from '../../assets/images/services/analytics.webm';

export default function() {
    return (
        <div className="e-commerce-services services-section">  
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <div  iv className="services-section-head">
                            <h2 className="services-section-title">E-commerce Services</h2> 
                            <span className="services-section-desc">End-to-end e-commerce solutions. Providing implementation, integration, support, and monitoring services of B2C and B2B e-commerce platforms.</span>
                            <Button href="/services/e-commerce/" >Learn more <img src={require('../../assets/images/next_service.png')} alt="arrow right" /></Button>
                        </div>
                    </Col>
                </Row>
                <Tabs>
                    <div className="e-commerce-tabs services-section-tabs">
                        <Row>
                            <Col xs={12} md={12} lg={6} className="tabs-list-ctn">
                                <TabList>
                                    <Tab>
                                        <h3>E-commerce engineering</h3>
                                        <p>Built for the present and optimized for the future. Tailored platforms to revenue brands’ model and traffic patterns. </p>
                                    </Tab>
                                    <Tab>
                                        <h3>E-commerce strategy</h3>
                                        <p>Ensuring fast systems that run properly while planning for how brands’ will need them to run in the future. </p>
                                    </Tab>
                                    <Tab>
                                        <h3>Analytics + automation</h3>
                                        <p>Actionable analytics to help brands make informed e-commerce decisions. Automations that turn browsers into buyers.</p>
                                    </Tab>
                                </TabList>
                            </Col>
                            <Col xs={12} md={12} lg={6} className="gif-img-ctn">
                                <TabPanel>
                                    <video loop={true} autoPlay="autoplay" muted>
                                        <source src={ECommerceEngineering} type="video/webm"></source>
                                    </video>
                                </TabPanel>
                                <TabPanel>
                                    <video loop={true} autoPlay="autoplay" muted>
                                        <source src={ECommerceStrategy} type="video/webm"></source>
                                    </video>
                                </TabPanel>
                                <TabPanel>
                                    <video loop={true} autoPlay="autoplay" muted>
                                        <source src={Analytics} type="video/webm"></source>
                                    </video>
                                </TabPanel>
                            </Col>
                        </Row> 
                    </div>
                </Tabs>
            </Container>
        </div>
    )
}
